<template>
  <div>
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        v-model="value1"
        :title="'就诊人：' + patientName"
        :options="patientList"
        @change="changePatient"
      >
        <van-cell center title="去添加" @click="addAccount" />
      </van-dropdown-item>
      <van-dropdown-item
        v-model="value2"
        :title="'时间：' + timeName"
        :options="timeList"
        @change="changeTime"
      />
    </van-dropdown-menu>
    <van-tabs color="#1691fe" title-active-color="#1691fe" @click="changeTab">
      <van-tab
        v-for="(item, index) in tabOptions"
        :key="index"
        :title="item.label"
        :name="item.value"
      />
    </van-tabs>
    <div v-if="list.length > 0" class="laboratoryList">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="title" @click="toInfo(item)">
          {{ item.checkTime }}
          <span style="color: #1691fe"><van-icon name="arrow" /></span>
        </div>
        <div class="list" @click="toInfo(item)">
          <div class="grid-table">
            <div>检验项目</div>
            <div>{{ item.orderName }}</div>
            <div>检验单号</div>
            <div>{{ item.inspectionID }}</div>
            <div>医生</div>
            <div>{{ item.doctorName }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无检验信息，请重新查询" />
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import { Dialog } from 'vant'

  import { Toast } from 'vant'
  import { getInspectList } from '@/api/lis/lis'
  import { getCloudImageUrl } from '@/api/his/his'
  import {
    changeDefaultAccount,
    getOptions,
    getUserInfo,
  } from '@/api/wechat/account/account'
  export default {
    name: 'Inspect',
    data() {
      return {
        list: [],
        search: {
          CardNo: '',
          BeginTime: '',
          EndTime: '',
          AccountID: 0,
        },
        tabOptions: [
          {
            value: 1,
            label: '检验',
          },
          {
            value: 2,
            label: '云影像',
          },
        ],
        openId: '',

        value1: null,
        value2: '',
        patientName: '', //就诊人姓名
        timeName: '', //时间
        patientList: [], //就诊人列表,
        timeList: [
          { text: '近7天', value: 1 },
          { text: '近1月', value: 2 },
          { text: '近3月', value: 3 },
          { text: '近1年', value: 4 },
          { text: '全部', value: 0 },
        ],
      }
    },
    async created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      if (userInfo != null) {
        if (userInfo.card_No == '') {
          this.getTip('当前就诊人未通过身份证绑定')
        } else {
          this.openId = store.getters['user/openId']
          this.patientName = userInfo.name
          this.search.CardNo = userInfo.card_No
          await this.getUser()
          this.value1 = userInfo.id
          this.value2 = 1
          this.timeName = '近7天'
          await this.pageInit()
          await this.fetchData()
        }
      } else {
        this.getTip('暂未绑定就诊人，点击确定前往。')
      }
    },
    beforeDestroy() {
      Dialog.close()
    },
    methods: {
      getTip(msg) {
        Dialog.alert({
          message: msg,
          closeOnPopstate: false,
        }).then(() => {
          this.$router.push({
            path: '/message',
            query: { sign: 'gzrgl' },
          })
        })
      },
      //初始化
      async pageInit() {
        this.search.EndTime = moment(new Date())
          .add(this.day, 'day')
          .format('yyyy-MM-DD 23:59:59')
        switch (this.value2) {
          case 0:
            this.search.BeginTime = ''
            this.search.EndTime = ''
            break
          case 1:
            this.search.BeginTime = moment(new Date())
              .add(-7, 'day')
              .format('yyyy-MM-DD 00:00:00')
            break
          case 2:
            this.search.BeginTime = moment(new Date())
              .add(-1, 'month')
              .format('yyyy-MM-DD 00:00:00')
            break
          case 3:
            this.search.BeginTime = moment(new Date())
              .add(-3, 'month')
              .format('yyyy-MM-DD 00:00:00')
            break
          case 4:
            this.search.BeginTime = moment(new Date())
              .add(-1, 'year')
              .format('yyyy-MM-DD 00:00:00')
            break
        }
      },
      //获取数据
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getInspectList(this.search)
        this.list = data
        Toast.clear()
      },
      //获取云影像
      async fetchCloudImageUrl() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getCloudImageUrl({ CardNo: this.search.CardNo })
        window.location.href = data
        this.list = []
        Toast.clear()
      },
      //更换就诊人
      async changePatient(value) {
        this.patientName = this.patientList.find(
          (item) => item.value === value
        ).text
        const { msg } = await changeDefaultAccount({
          OpenId: this.openId,
          AccountID: value,
        })
        await Toast(msg)
        await this.getUserInfo()
        let userInfo = JSON.parse(store.getters['user/userInfo'])
        if (userInfo != null) {
          this.search.CardNo = userInfo.card_No
          await this.pageInit()
          await this.fetchData()
        }
      },
      //切换标签
      changeTab(val) {
        switch (val) {
          case 1:
            this.fetchData()
            break
          case 2:
            if (this.search.CardNo === '') {
              Dialog.alert({
                title: '提示',
                message: '当前就诊人未通过身份证绑定，无法查看云影像',
              })
            } else {
              this.fetchCloudImageUrl()
            }
            break
        }
      },

      toInfo(item) {
        let path =
          item.inspectionID.substring(0, 8) === '00000000'
            ? '/inspectWSWInfo'
            : '/inspectInfo'
        this.$router.push({
          path: path,
          query: {
            inspectionID: this.$Base64.encode(item.inspectionID),
            deptName: this.$Base64.encode(item.deptName),
            patientName: this.$Base64.encode(item.patientName),
            checkTime: this.$Base64.encode(item.checkTime),
            doctorName: this.$Base64.encode(item.doctorName),
          },
        })
      },

      //获取就诊人选择
      async getUser() {
        const { data } = await getOptions({ OpenId: this.openId })
        for (let i = 0; i < data.length; i++) {
          this.patientList.push(
            JSON.parse(
              '{"text":"' + data[i].name + '","value":' + data[i].value + '}'
            )
          )
        }
      },

      addAccount() {
        this.$router.push({
          path: '/message',
          query: { sign: 'gzrgl' },
        })
      },

      async getUserInfo() {
        const { data } = await getUserInfo({ OpenId: this.openId })
        await store.dispatch('user/getUserInfo', JSON.stringify(data))
      },

      async changeTime(value) {
        this.timeName = this.timeList.find((item) => item.value === value).text
        await this.pageInit()
        await this.fetchData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-tabs {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
  }
  .van-dropdown-menu {
    width: 95%;
    margin: auto;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 10px;
    ::v-deep .van-dropdown-item {
      top: 48px !important;
      width: 95%;
      margin: auto;
      .van-dropdown-item__content {
        height: 100vh;
        max-height: none;
      }
    }
  }
  .van-dropdown-menu {
    ::v-deep .van-dropdown-menu__title--active {
      color: #1691fe;
    }
  }
  .laboratoryList {
    font-family: 'Microsoft Yahei';
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }
  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
</style>
